import {timeFormat, numberFormat} from "@custom/index";

export function tableColumn(_this) {
    return [
        {
            type: "index",
            label: "序号",
            width: 60,
        },
        {
            prop: "paidTime",
            label: "交易时间",
            width: 150,
            render: (h, {row}) => {
                const {paidTime = null} = row;
                return h("span", paidTime ? timeFormat(new Date(paidTime),"yyyy-MM-dd HH:mm:ss") : "");
            }
        },
        {
            prop: "apartmentName",
            label: "房间名称",
            width: 175,
        },
        {
            prop: "leasorName",
            label: "住户名称",
            width: 80,
        },
        {
            prop: "leasorPhone",
            label: "联系电话",
            width: 100,
        },
        {
            prop: "payBillAmount",
            label: "账单金额",
            width: 90,
            render: (h, {row}) => {
                const {payBillAmount = 0} = row;
                return h("span", numberFormat(payBillAmount / 100));
            }
        },
        {
            prop: "payBillTypeName",
            label: "项目名称",
            width: 80,
        },
        {
            prop: "",
            label: "账单时间",
            width: 175,
            render: (h, {row}) => {
                const {startDate = null, endDate = null} = row;
                if (startDate && endDate) return h("span", timeFormat(new Date(startDate)) + "-" + timeFormat(new Date(endDate)));
            }
        },
        {
            prop: "receivableAmount",
            label: "收款",
            width: 120,
            render: (h, {row}) => {
                const {receivableAmount = 0} = row;
                return h("span", numberFormat(receivableAmount / 100));
            }
        },
        {
            prop: "payAmount",
            label: "付款",
            width: 90,
            render: (h, {row}) => {
                const {payAmount = 0} = row;
                return h("span", numberFormat(payAmount / 100));
            }
        },
        {
            prop: "total",
            label: "合计",
            width: 100,
            render: (h, {row}) => {
                const {total = 0} = row;
                return h("span", numberFormat(total / 100));
            }
        },
        {
            prop: "comment",
            label: "备注",
        },
        {
            prop: "paymentMethod",
            label: "收款方式",
            width: 90,
        },
        {
            prop: "weChatPaySerialNumber",
            label: "微信支付流水号",
            width: 225
        },
    ];
}

